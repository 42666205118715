@import "~expano-components/styles/status-colors.scss";
@import "~expano-components/styles/form.scss";
@import "~expano-components/styles/input.scss";
@import "~expano-components/styles/textarea.scss";
@import "~expano-components/styles/check.scss";
@import "~expano-components/styles/buttons.scss";
@import "~expano-components/styles/labels.scss";
@import "~expano-components/styles/tree.scss";

@import "~expano-components/styles/popup.scss";
@import "~expano-components/styles/image-uploader.scss";
// @import "~expano-components/styles/breadcrumbs.scss";
@import "~expano-components/styles/topbar.scss";
@import "~expano-components/styles/sidebar.scss";
@import "~expano-components/styles/main.scss";
@import "~expano-components/styles/login.scss";
@import "~expano-components/styles/change-app-modal.scss";
@import "~expano-components/styles/tradesk_label.scss";
@import "~expano-components/styles/page-panel.scss";
@import "~expano-components/styles/status-label.scss";

.page-panel {
  .info-holder__icon {
    svg {
      [fill] {
        fill: #5d78ff;
      }
    }
  }
}

.dzu-dropzone {
  &::-webkit-scrollbar {
    display: none;
  }
  label {
    display: flex;
  }
}

.wrapper {
  display: flex;
  &.jc-sb {
    justify-content: space-between;
  }
  &.jc-fs {
    justify-content: flex-start;
  }
  &.jc-fe {
    justify-content: flex-end;
  }
  &.al-c {
    align-items: center;
  }
}
.block {
  padding: 20px;
  flex: 1;
}

.popup.lg {
  max-width: 800px;
}
.popup {
  &__header {
    .icon {
      svg {
        [fill] {
          fill: #5d78ff;
        }
      }
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
