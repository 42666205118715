.actions-container {
  position: relative;
  .btn-actions {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    .dot {
      width: 4px;
      height: 4px;
      background-color: #b5b5c3;
      border-radius: 2px;
      margin: 0 1.5px;
      transition: 0.2s;
    }
  }
  .actions-dropdown {
    display: none;
    padding: 5px 10px;
    border-radius: 4px;
    min-width: 190px;
    background-color: #ffffff;
    box-shadow: 0px 0px 50px #563f6926;
    position: absolute;
    right: 0;
    top: 100%;
    &__item {
      margin: 5px 0;
      padding: 10px 5px;
      display: flex;
      align-items: center;
      transition: 0.2s;
      svg {
        margin-right: 10px;
      }
      span {
        font-size: 13px;
        line-height: 20px;
        color: #646c9a;
        white-space: nowrap;
      }
      &:hover {
        opacity: 0.6;
      }
      &:active {
        transform: scale(0.95);
      }
    }
  }
  &:hover {
    .actions-dropdown {
      display: block;
    }
    .btn-actions {
      background-color: #f0f3ff;
      .dot {
        background-color: #5d78ff;
      }
    }
  }
}