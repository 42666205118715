@import "~react-redux-toastr/lib/css/react-redux-toastr.min.css";
@import "~react-date-picker/dist/DatePicker.css";
@import "~react-calendar/dist/Calendar.css";
@import "~react-markdown-editor-lite/lib/index.css";
@import "reset.scss";
@import "colors.scss";
@import "mixins.scss";
@import "typography.scss";
@import "expano-components.scss";
@import "partials/dashboard.scss";
@import "partials/page-loader.scss";
@import "partials/calendar.scss";
@import "partials/main-table.scss";
@import "partials/top-line-actions.scss";

.rc-md-navigation {
  background: #fff;
}

.rc-md-editor .editor-container .sec-md .input {
  padding-top: 20px;
  &::-webkit-scrollbar {
    display: none;
  }
}

.rc-md-editor .tool-bar {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
}

[class*="indicatorContainer"] {
  padding: 0 10px !important;
}

$spaceamounts: (
  0,
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  75,
  100
);

$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }
    .m-#{$space} {
      margin: #{$space}px !important;
    }
    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
    .p-#{$space} {
      padding: #{$space}px !important;
    }
  }
}

