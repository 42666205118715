.calendar-group {
  & > div {
    width: 100%;
  }
  .MuiInputBase-root {
    position: relative;
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
    input[type="datetime-local" i],
    input[type="date" i] {
      padding-right: 0;
      &::-webkit-calendar-picker-indicator {
        cursor: pointer;
        position: absolute;
        height: 24px;
        left: initial;
        right: 1px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        bottom: 1px;
        top: 1px;
        width: 28px;
        border: none;
        transform: none;
        background-color: #f3f6f9;
        border-left: 1px solid #e2e5ec;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj48cmVjdCBmaWxsPSIjNTk1ZDZlIiB3aWR0aD0iMTUuODMzIiBoZWlnaHQ9IjMuMzMzIiByeD0iMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMS42NjcgNC4xNjcpIj48L3JlY3Q+PHJlY3QgZmlsbD0iIzU5NWQ2ZSIgb3BhY2l0eT0iMC4zIiB3aWR0aD0iMTUuODMzIiBoZWlnaHQ9IjguMzMzIiByeD0iMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMS42NjcgOS4xNjcpIj48L3JlY3Q+PC9zdmc+");
        background-size: 20px 20px;
        background-position: center;
        background-repeat: no-repeat;
        transition: 0.2s;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  .MuiInputBase-input {
    cursor: pointer;
    width: 100%;
    padding: 0px;
    border-radius: 4px;
    border: none;
    border: 1px solid #e2e5ec;
    padding: 7px 10px;
    min-height: 30px;
    box-sizing: border-box;
    width: 100%;
    outline: none;
    font-size: 13px;
    line-height: 20px;
    font-family: "Poppins";
    transition: 0.2s;
  }
  .react-date-picker__button {
    width: 32px !important;
    height: 32px !important;
  }
  .react-calendar__tile{
    font-size: 13px;
  }
}

.form-group.error {
  .MuiInputBase-input {
    border-color: #ff0000;
  }
}
