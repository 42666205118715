.dashboard {
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 13px #523f690c;
  border-radius: 5px;
  margin: 10px 0;
  margin-bottom: 8px;
  &--warning {
    background-color: #f9e6a8!important;
  }
  &__tabs {
    display: flex;
    background-color: #f2f3f8;
    .tab {
      flex: 1;
      background-color: #ebecf3;
      padding: 9px 0;
      text-align: center;
      cursor: pointer;
      position: relative;
      &:last-child {
        &::before {
          display: none;
        }
      }
      &:hover {
        background-color: #e5e6ef;
      }
      &.active {
        background-color: white;
        position: relative;
        &:hover {
          background-color: white;
        }
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #5d78ff;
          height: 2px;
        }
        &:before {
          display: none;
        }
      }
    }
  }
  &--small {
    flex-basis: 49.2%;
  }
  &__button-section {
    padding: 20px 30px;
  }
  &__list {
    &-item {
      display: flex;
      align-items: center;
      padding: 20px 30px;
      position: relative;
      .country {
        margin-right: auto;
        &-label--hidden {
          visibility: hidden;
        }
      }
      .no-margin {
        margin: 0;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $separator;
      }
    }
  }
  &__form {
    padding: 30px;
    &__submit {
      padding: 20px 30px;
    }
    &__footer {
      border-top: 1px solid $separator;
      display: flex;
      justify-content: flex-end;
      padding: 10px 30px;
      button {
        margin-left: 15px;
      }
      &.md {
        justify-content: flex-start;
        .box {
          width: 100%;
          max-width: 600px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
    &--pr-10 {
      //padding-left: 10px;
      padding-right: 10px;
    }
  }
  &__channel-label {
    padding: 30px;
    padding-top: 0;
    .channel-label-text {
      margin-bottom: 11px;
    }
  }
  &__header {
    display: flex;
    padding: 20px 30px 20px;
    align-items: center;
    border-bottom: 1px solid $separator;
    &--edit {
      padding: 18px 30px;
    }
  }
  &__title {
    margin-right: auto;
    margin-left: 10px;
  }
  &__searchfield {
    padding: 18px 30px;
    display: flex;
    .search-box {
      flex: 1;
      padding: 10px;
      border: 1px solid #e2e5ec;
      border-radius: 4px;
    }
  }
}
.main-dashboard {
  min-height: 100vh;
  background-color: $dashboard-background;
  &__container {
    padding-top: 10px;
  }
}
.icon__container {
  path {
    fill: $svg-icon;
  }
  circle {
    fill: $svg-icon;
  }
  .svg-rect {
    fill: $svg-icon;
  }
}

.dashboard-wrapper-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.column-1 {
  flex-basis: calc(33.3333% - 5px);
  &--container {
    display: flex;
    flex-direction: column;
  }
}

.column-2 {
  flex-basis: calc(66.666% - 5px);
}

.column-3 {
  flex-basis: 100%;
}

.column-half {
  flex-basis: calc(50% - 5px);
}
